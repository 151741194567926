<template>
  <div v-if="displayButton" class="maco-button">
    <ReadMore
      v-if="isReadMore"
      :id="googleTag"
      :button="button"
      :lang="lang"
      @click="onButtonClick"
    />

    <ButtonBase
      v-else-if="isButtonBase"
      :id="googleTag"
      :button="button"
      @click="onButtonClick"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiButtonProps } from '~/types'
import ButtonBase from '~ui/components/Ui/ButtonBase/ButtonBase.vue'
import ReadMore from '~ui/components/Ui/ReadMore/ReadMore.vue'
import { isLinkValid } from '~ui/helpers/linkUtils'

const props = defineProps<{
  button?: UiButtonProps
  lang?: string
  googleTag?: string
}>()

const isButtonLinkValid = computed(() => isLinkValid(props.button?.link))

const isReadMore = computed(() => {
  return props.button?.isArrow && isButtonLinkValid.value
})

const isButtonBase = computed(() => {
  return props.button?.content && isButtonLinkValid.value
})

const displayButton = computed(() => isReadMore.value || isButtonBase.value)

const emit = defineEmits(['click'])
const onButtonClick = () => {
  emit('click')
}
</script>

<style lang="scss">
.maco-button {
  display: inline-flex;
}
</style>
